export class DatabaseEntities<T> {
    id: number;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;

    constructor(values?: Partial<T>) {
        if (!values) {
            return;
        }

        Object.assign(this, values);
    }

    public copyWith(values?: Partial<T>) {
        const copy = new (this.constructor as any)(this) as T;
        Object.assign(copy, values);
        return copy;
    }
}
