import { Injectable } from "@angular/core";
import { _BaseApi } from "../../../base.api";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Assets } from "../models/assets.model";
import { FormDataUtils } from "@recursyve/nice-ui-kit.v2";
import { UploadAssetDto } from "../dto/upload-asset.dto";

@Injectable()
export class AssetsApi extends _BaseApi {
    constructor(protected http: HttpClient) {
        super("asset", http);
    }

    public uploadAssets(dto: UploadAssetDto, files: File[]): Observable<Assets[]> {
        const data = new FormData();
        files.forEach(file => data.append("files", file));

        FormDataUtils.appendFormDataWithDto(data, dto);

        return this.post("", data, { type: Assets });
    }

    public getById(id: number): Observable<Assets> {
        return this.get(`${id}`);
    }
}
