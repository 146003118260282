import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { I18nUtils } from "../utils/i18n.utils";

@Injectable({ providedIn: "root" })
export class I18nService {
    constructor(private translateService: TranslateService) {}

    public getDefaultLang(): string {
        const browserLang = localStorage.getItem("lang") || this.translateService.getBrowserLang();
        if (I18nUtils.SUPPORTED_LANGUAGES.some(x => x === browserLang)) {
            return browserLang;
        }

        return I18nUtils.DEFAULT_LANGUAGE;
    }
}
