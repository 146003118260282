import { Injectable } from "@angular/core";
import { LoginStore } from "./login.store";
import { Router } from "@angular/router";
import { ApiService } from "../../../api/api.service";

@Injectable()
export class LoginService {
    constructor(
        private store: LoginStore,
        private api: ApiService,
        private router: Router
    ) {
    }

    public async login(pin: string): Promise<void> {
        this.store.setLoading(true);
        try {
            this.resetError();
            const loggedIn = await this.api.auth.login({ pin }).toPromise();
            if (loggedIn) {
                this.router.navigate(["/dashboard"]);
            } else {
                this.store.setError(true);
            }
        } catch (e) {
            this.store.setError(e);
        } finally {
            this.store.setLoading(false);
        }
    }

    private resetError() {
        this.store.setError(null);
    }
}
