import { Injectable } from "@angular/core";
import { Query } from "@datorama/akita";
import { LoginState, LoginStore } from "./login.store";

@Injectable()
export class LoginQuery extends Query<LoginState> {
    constructor(store: LoginStore) {
        super(store);
    }
}
