import { NgModule } from "@angular/core";
import { LoginService } from "./store/login.service";
import { LoginStore } from "./store/login.store";
import { LoginQuery } from "./store/login.query";
import { LoginComponent } from "./login.component";
import { FlexModule } from "@angular/flex-layout";
import { NiceLayoutModule, NiceLoadingSpinnerModule } from "@recursyve/nice-ui-kit.v2";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";

@NgModule({
    imports: [
        FlexModule,
        NiceLoadingSpinnerModule,
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        NiceLayoutModule
    ],
    providers: [LoginQuery, LoginService, LoginStore],
    declarations: [LoginComponent],
    exports: [LoginComponent]
})
export class LoginModule {}
