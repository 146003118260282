import { Injectable } from "@angular/core";
import { AclApi } from "../acl.api";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Accounts } from "./models/accounts.model";

@Injectable()
export class AccountApi extends AclApi {
    constructor(http: HttpClient) {
        super("account", http);
    }

    public getInfo(): Observable<Accounts> {
        return this.get<Accounts>("info");
    }
}
