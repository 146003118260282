import { Inject, Injectable } from "@angular/core";
import { AclGateway } from "../../acl.gateway";
import { Manager } from "socket.io-client";
import { NiceToastService } from "@recursyve/nice-ui-kit.v2";
import { Observable } from "rxjs";
import { Equipments } from "../models/equipments.model";
import { EquipmentsEventType } from "./events/types/equipments.event-type";

@Injectable()
export class EquipmentsGateway extends AclGateway {
    constructor(@Inject(Manager) manager: Manager, niceToastService: NiceToastService) {
        super("equipment", manager, niceToastService);
    }

    public get equipmentsUpdated$(): Observable<Equipments[]> {
        return this.listenForEvent(EquipmentsEventType.EquipmentsUpdated);
    }
}
