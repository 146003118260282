import { HashLocationStrategy, LOCATION_INITIALIZED, LocationStrategy } from "@angular/common";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, ErrorHandler, Injector, NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatNativeDateModule } from "@angular/material/core";
import { MatIconModule } from "@angular/material/icon";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NiceAsyncTypeaheadModule, NiceConfigModule, NiceModule, NiceSweetAlertModule, NiceToastModule } from "@recursyve/nice-ui-kit.v2";
import { AkitaNgDevtools } from "@datorama/akita-ngdevtools";
import { AkitaNgRouterStoreModule } from "@datorama/akita-ng-router-store";
import { Manager } from "socket.io-client";
import { AppComponent } from "./app.component";
import { ROUTES } from "./app.routes";
import { DashboardModule } from "./features/dashboard/dashboard.module";
import { ApiModule } from "./api/api.module";
import { AppStore } from "./store/app.store";
import { AppQuery } from "./store/app.query";
import { GlobalErrorHandler } from "./handlers/global-error.handler";
import { environment } from "../environments/environment";
import { I18nService } from "./providers/i18n.service";
import { LoginModule } from "./features/login/login.module";
import { AuthenticatedGuard } from "./guards/authenticated.guard";
import { NotAuthenticatedGuard } from "./guards/not-authenticated.guard";
import { ClientTypeaheadApi } from "./api/acl/client/client-typeahead.api";
import { NgxFormGeneratorModule } from "@recursyve/ngx-form-generator";
import { TicketTypeaheadApi } from "./api/acl/ticket/ticket-typeahead.api";
import { EquipmentTypeaheadProvider } from "./api/acl/equipments/apis/equipments-typeahead.api";
import { ClientAddressTypeaheadProvider } from "./api/acl/client/client-address-typeahead.api";
import { NgxMaskModule } from "ngx-mask";
import { NgxMatDatetimePickerModule } from "@angular-material-components/datetime-picker";
import { EquipmentUniqueIdentifierValidator } from "./features/dashboard/equipments/modals/upsert-equipments-modal/validators/equipment-unique-identifier.validator";

export function appInitializerFactory(translate: TranslateService, i18nService: I18nService, injector: Injector) {
    return () =>
        new Promise<any>((resolve: any) => {
            const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
            locationInitialized.then(async () => {
                const lang = i18nService.getDefaultLang();
                translate.setDefaultLang(lang);
                try {
                    await translate.use(lang).toPromise();
                } finally {
                    resolve();
                }
            });
        });
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(ROUTES, { relativeLinkResolution: "legacy" }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        NiceModule,
        NiceConfigModule.forRoot({
            theme: "default",
            scheme: "light"
        }),
        NiceAsyncTypeaheadModule.register([ClientTypeaheadApi, TicketTypeaheadApi, EquipmentTypeaheadProvider, ClientAddressTypeaheadProvider]),
        NiceSweetAlertModule.forRoot(),
        NgxFormGeneratorModule.forRoot({ asyncValidators: [EquipmentUniqueIdentifierValidator] }),
        NgxMaskModule.forRoot(),
        MatNativeDateModule,
        MatButtonModule,
        MatIconModule,
        ApiModule,
        DashboardModule,
        NiceToastModule.forRoot({ appearance: "border" }),
        environment.production ? [] : AkitaNgDevtools.forRoot(),
        AkitaNgRouterStoreModule,
        LoginModule,
        NgxMatDatetimePickerModule
    ],
    providers: [
        AppStore,
        AppQuery,
        I18nService,
        AuthenticatedGuard,
        NotAuthenticatedGuard,
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [TranslateService, I18nService, Injector],
            multi: true
        },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        {
            provide: Manager,
            useValue: new Manager(environment.wsUrl, {
                autoConnect: false,
                withCredentials: true,
                transports: ["websocket", "polling"]
            })
        }
    ],
    exports: [AppComponent],
    bootstrap: [AppComponent]
})
export class AppModule {
}
