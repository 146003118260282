import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { _BaseApi } from "../../../base.api";
import { GrainBoxSizes } from "../models/grain-box-sizes.model";

@Injectable()
export class GrainBoxSizesApi extends _BaseApi {
    constructor(private http: HttpClient) {
        super("grain-box-size", http);
    }

    public getAll(): Observable<GrainBoxSizes[]> {
        return this.get("", { type: GrainBoxSizes });
    }
}
