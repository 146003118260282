import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { UpsertEquipmentDto } from "../dto/upsert-equipment.dto";
import { _BaseApi, _BaseFilterApi } from "../../../base.api";
import { Equipments } from "../models/equipments.model";
import { EquipmentsGateway } from "../gateway/equipments.gateway";
import { EquipmentsGetAllDto } from "../dto/equipments-get-all.dto";
import { SearchEquipmentsDto } from "../dto/search-equipments.dto";
import { SpreaderWheels } from "../models/spreader-wheels.model";

@Injectable()
export class SpreaderWheelsApi extends _BaseApi {
    constructor(private http: HttpClient) {
        super("spreader-wheel", http);
    }

    public getAll(): Observable<SpreaderWheels[]> {
        return this.get("", { type: SpreaderWheels });
    }
}
