import { Injectable } from "@angular/core";
import { Store, StoreConfig } from "@datorama/akita";

export interface LoginState {
}

const initialState: LoginState = {};

@Injectable()
@StoreConfig({ name: "login" })
export class LoginStore extends Store<LoginState> {
    constructor() {
        super(initialState);
    }
}
