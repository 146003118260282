import { Injectable } from "@angular/core";
import { AclFilterApi } from "../../filter/acl-filter.api";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { EquipmentStatusHistories } from "../models/equipment-status-histories.model";

@Injectable()
export class EquipmentStatusHistoriesApi extends AclFilterApi<EquipmentStatusHistories> {
    constructor(http: HttpClient) {
        super("equipment-status-history", http);
    }

    public getById(id: number): Observable<EquipmentStatusHistories> {
        return this.get(`${id}`);
    }
}
