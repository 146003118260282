import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { _BaseApi } from "../../../base.api";
import { NurseWheels } from "../models/nurse-wheels.model";
import { OtherEquipmentNames } from "../models/other-equipment-names.model";

@Injectable()
export class OtherEquipmentNamesApi extends _BaseApi {
    constructor(private http: HttpClient) {
        super("other-equipment-name", http);
    }

    public getAll(): Observable<OtherEquipmentNames[]> {
        return this.get("", { type: OtherEquipmentNames });
    }
}
