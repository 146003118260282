import { Injectable } from "@angular/core";
import { AsyncValidator, registerAsyncValidatorDecorator } from "@recursyve/ngx-form-generator";
import { ApiService } from "../../../../../../api/api.service";
import { AbstractControl, ValidationErrors } from "@angular/forms";
import { Observable, of, switchMap, timer } from "rxjs";
import { catchError, map } from "rxjs/operators";

export function EquipmentUniqueIdentifier() {
    return registerAsyncValidatorDecorator({
        name: "EquipmentUniqueIdentifierValidator"
    });
}

@Injectable()
export class EquipmentUniqueIdentifierValidator extends AsyncValidator {
    public name = "EquipmentUniqueIdentifierValidator";

    constructor(private apiService: ApiService) {
        super();
    }

    public validate(control: AbstractControl): Observable<ValidationErrors | null> {
        if (!control.value || control.value === "") {
            return of(null);
        }

        if (control.parent?.get("id")?.value) {
            return of(null);
        }

        return timer(200).pipe(
            switchMap(() =>
                this.apiService.equipment.findAllByIdentifier(control.value)
                    .pipe(
                        map(equipments => {
                            if (equipments.length) {
                                return {
                                    duplicated_identifier: true
                                };
                            }

                            return null;
                        }),
                        catchError(err => {
                            if (err?.status === 404) {
                                return of(null);
                            }

                            throw err;
                        })
                    )
            )
        );
    }
}
