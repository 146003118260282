import { Query } from "@datorama/akita";
import { RolesEnum } from "../api/acl/account/models/enums/roles.enum";
import { AppState, AppStore } from "./app.store";
import { Observable } from "rxjs";
import { InfoModel } from "../api/info/models/info.model";
import { Injectable } from "@angular/core";
import { Accounts } from "../api/acl/account/models/accounts.model";

@Injectable()
export class AppQuery extends Query<AppState> {
    constructor(protected store: AppStore) {
        super(store);
    }

    public selectServerState(): Observable<InfoModel | null> {
        return this.select(state => state.serverState);
    }

    public selectCurrentUser(): Observable<Accounts | undefined> {
        return this.select(s => s.currentUser);
    }

    public selectCurrentRole(): Observable<RolesEnum | undefined> {
        return this.select(s => s.currentRole);
    }
}
