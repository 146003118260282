<nice-vertical-navigation
    class="print:hidden"
    [mode]="isMobile ? 'over' : 'side'"
    [name]="'navbar'"
>
    <ng-container niceVerticalNavigationHeader>
        <div class="h-full justify-center items-center pt-24 pb-8 px-6">
            <img class="logo-icon" src="../../../../../assets/images/logo-Agrocentre.svg">
        </div>
    </ng-container>
</nice-vertical-navigation>
