import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { _BaseApi } from "../../../base.api";
import { SpreaderHeights } from "../models/spreader-heights.model";

@Injectable()
export class SpreaderHeightsApi extends _BaseApi {
    constructor(private http: HttpClient) {
        super("spreader-height", http);
    }

    public getAll(): Observable<SpreaderHeights[]> {
        return this.get("", { type: SpreaderHeights });
    }
}
