import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { ControlValueAccessor, FormGroup } from "@angular/forms";
import { MatStepper } from "@angular/material/stepper";
import { GeneratedFormGroup } from "@recursyve/ngx-form-generator";
import { Subscription } from "rxjs";

@Component({
    template: ``
})
export abstract class BaseFormComponent<Form> implements OnInit, OnDestroy, ControlValueAccessor {
    @ViewChild(MatStepper)
    private stepper: MatStepper;

    @Input()
    public loading = false;

    @Output()
    public submit = new EventEmitter<Form>();

    protected valueChangeSub$: Subscription;
    private propagate: (form: Form) => void = () => {
    };

    protected constructor(public formGroup: GeneratedFormGroup<Form>) {
    }

    public ngOnInit(): void {
        this.valueChangeSub$ = this.formGroup.valueChanges.subscribe(() => {
            this.onValueChange(this.formGroup.getRawValue());
        });
    }

    public ngOnDestroy(): void {
        this.valueChangeSub$?.unsubscribe();
    }

    public registerOnChange(fn: (form: Form) => void): void {
        this.propagate = fn;
    }

    public registerOnTouched(fn: (form: Form) => void): void {
        // NO-OP
    }

    public writeValue(form: Form): void {
        if (form) {
            this.formGroup.patchValue(form);
        }
    }

    public clickSubmit(): void {
        if (!this.validate()) {
            return;
        }
        this.submit.emit(this.formGroup.getRawValue());
    }

    public clickNext(index: number): void {
        this.validateStep(index);
        if (this.stepper) {
            this.stepper.next();
        }
    }

    public clickPrevious(): void {
        if (this.stepper) {
            this.stepper.previous();
        }
    }

    protected onValueChange(value: Form): void {
        this.propagate(value);
    }

    public validate(): boolean {
        if (this.formGroup.valid) {
            return true;
        }
        this.formGroup.markAllAsTouched();
        return false;
    }

    protected validateStep(index: number): boolean {
        const group = this.getCurrentStepGroup(index);
        if (group?.valid) {
            return true;
        }
        group?.markAllAsTouched();
        return false;
    }

    protected getCurrentStepGroup(index: number): FormGroup | null {
        return null;
    }
}
