import { Component, ElementRef, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { AppService } from "../../store/app.service";

@Component({
    selector: "acl-dashboard",
    templateUrl: "dashboard.template.html",
    styleUrls: ["dashboard.style.scss"],
    encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit {
    constructor(
        private router: Router,
        private elementRef: ElementRef,
        private appService: AppService
    ) {
    }

    public async ngOnInit() {
        this.router.events.subscribe(_ => {
            this.elementRef.nativeElement.scrollIntoView();
        });
        await this.appService.loadCurrentUser();
    }
}
