import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Clients } from "./models/clients.model";
import { AclApi } from "../acl.api";
import { SearchClientDto } from "./dto/search-client.dto";

@Injectable()
export class ClientApi extends AclApi {
    constructor(http: HttpClient) {
        super("client", http);
    }

    public getById(id: string): Observable<Clients> {
        return this.get<Clients>(encodeURIComponent(id));
    }

    public search(dto: SearchClientDto): Observable<Clients[]> {
        return this.post<Clients[]>("search", dto);
    }
}
