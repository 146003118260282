import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AclApi } from "../acl.api";
import { Bins } from "../bin/models/bins.model";
import { MixerGateway } from "./gateways/mixer.gateway";
import { MixersData } from "./models/mixers.model";

@Injectable()
export class MixerApi extends AclApi {
    public get gateway(): MixerGateway {
        return this.mixerGateway;
    }

    constructor(http: HttpClient, private mixerGateway: MixerGateway) {
        super("mixer", http);
    }

    public getAll(): Observable<MixersData[]> {
        return this.get();
    }

    public getBins(id: number): Observable<Bins[]> {
        return this.get(`${id}/bin`);
    }
}
