import { NiceAsyncTypeaheadProvider, NiceAsyncTypeaheadSearchResult } from "@recursyve/nice-ui-kit.v2";
import { lastValueFrom } from "rxjs";
import { Injectable } from "@angular/core";
import { Clients } from "./models/clients.model";
import { ClientApi } from "./client.api";
import { SearchClientDto } from "./dto/search-client.dto";

@Injectable()
export class ClientTypeaheadApi extends NiceAsyncTypeaheadProvider<Clients> {
    public resource = "clients";

    private pageSize = 20;

    constructor(private clientApi: ClientApi) {
        super();
    }

    public getById(id: number): Promise<Clients> {
        return lastValueFrom(this.clientApi.getById(id.toString()));
    }

    public async search(searchQuery: string, page: number): Promise<NiceAsyncTypeaheadSearchResult<Clients>> {
        const dto: SearchClientDto = {
            query: searchQuery,
            page,
            length: this.pageSize,
            primary: true
        };

        const result: Clients[] = await lastValueFrom(this.clientApi.search(dto));

        return {
            items: result,
            nextPage: result.length === this.pageSize ? page + 1 : null
        };
    }

    public format(client: Clients): string {
        return client.name?.trim() ?? "-";
    }
}
