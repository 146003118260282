import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { _BaseApi } from "../../../base.api";
import { NurseWheels } from "../models/nurse-wheels.model";

@Injectable()
export class NurseWheelsApi extends _BaseApi {
    constructor(private http: HttpClient) {
        super("nurse-wheel", http);
    }

    public getAll(): Observable<NurseWheels[]> {
        return this.get("", { type: NurseWheels });
    }
}
