import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { NiceMediaWatcherService, NiceNavigationService } from "@recursyve/nice-ui-kit.v2";
import { delay } from "rxjs/operators";

@Component({
    selector: "acl-navbar",
    templateUrl: "navbar.template.html",
    styleUrls: ["navbar.style.scss"],
    encapsulation: ViewEncapsulation.None
})
export class NavbarComponent implements OnInit {
    public isMobile = false;

    constructor(private mediaWatcher: NiceMediaWatcherService, private navigationService: NiceNavigationService) {}

    public ngOnInit(): void {
        this.mediaWatcher
            .asObservable()
            .pipe(delay(100)).subscribe(() => {
            if (this.mediaWatcher.isActive("lt-lg")) {
                this.isMobile = true;
                this.navigationService.getComponent("navbar")?.close();
            } else if (this.mediaWatcher.isActive("gt-md")) {
                this.isMobile = false;
                this.navigationService.getComponent("navbar")?.open();
            }
        });
    }
}
