import { Component, OnInit } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { registerLocaleData } from "@angular/common";
import localeFr from "@angular/common/locales/fr";
import localeEn from "@angular/common/locales/en";
import { NavigationService } from "./navigation/navigation.service";
import { AppService } from "./store/app.service";
import { I18nService } from "./providers/i18n.service";

@Component({
    selector: "acl-app",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.style.scss"]
})
export class AppComponent implements OnInit {

    constructor(
        private appService: AppService,
        private navigationService: NavigationService,
        private i18nService: I18nService,
        private translateService: TranslateService,
        private matIconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer
    ) {
        this.matIconRegistry.setDefaultFontSetClass("material-icons-outlined");
        this.matIconRegistry.addSvgIconSetInNamespace(
            "heroicons_outline",
            this.sanitizer.bypassSecurityTrustResourceUrl("assets/icons/heroicons-outline.svg")
        );
        this.matIconRegistry.addSvgIconSetInNamespace(
            "heroicons_solid",
            this.sanitizer.bypassSecurityTrustResourceUrl("assets/icons/heroicons-solid.svg")
        );
    }

    public async ngOnInit() {
        this.appService.init();
        await this.appService.loadServerState();
        await this.navigationService.init();

        const lang = this.i18nService.getDefaultLang();
        localStorage.setItem("lang", lang);
        this.updateLang(lang);
    }

    private updateLang(lang: string) {
        this.translateService.setDefaultLang(lang);
        registerLocaleData(localeFr);
        registerLocaleData(localeEn);
    }
}
