<nice-layout>
    <div id="login-page" class="h-full" [niceLoadingOverlay]="loading$ | async">
        <div class="backdrop flex flex-col justify-center items-center">
            <div id="login-form">
                <div class="flex flex-col items-center w-120 p-6"
                     [@animate]="{ value: '*', params: { duration: '300ms', y: '100px' } }">
                    <img class="logo pt-4 pb-8" src="../../../assets/images/logo-Agrocentre.svg" />
                    <div class="text-center text-md font-bold mb-6 text-black/50">
                        {{ "login.enter_employee_number" | translate }}
                    </div>
                    <form [formGroup]="formGroup" class="h-full w-full">
                        <mat-form-field appearance="none">
                            <input
                                id="pin"
                                matInput
                                type="password"
                                inputmode="numeric"
                                pattern="[0-9]*"
                                [placeholder]="'login.employee_number' | translate"

                                formControlName="pin"
                            >
                            <div matPrefix>
                                <i class="fad fa-user-circle fa-fw text-accent icon-size-6"></i>
                            </div>
                        </mat-form-field>
                        <mat-error class="mb-16 text-center" *ngIf="error$ | async">
                            {{ "login.login_error" | translate }}
                        </mat-error>
                        <div fxLayoutAlign="center">
                            <button mat-flat-button type="button" class="w-full"
                                    (click)="login()">
                                {{ "login.login" | translate }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</nice-layout>
