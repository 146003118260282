import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { _BaseApi } from "../../../base.api";
import { GrainBoxWheels } from "../models/grain-box-wheels.model";

@Injectable()
export class GrainBoxWheelsApi extends _BaseApi {
    constructor(private http: HttpClient) {
        super("grain-box-wheel", http);
    }

    public getAll(): Observable<GrainBoxWheels[]> {
        return this.get("", { type: GrainBoxWheels });
    }
}
