import { NiceAsyncTypeaheadProvider, NiceAsyncTypeaheadSearchResult } from "@recursyve/nice-ui-kit.v2";
import { lastValueFrom } from "rxjs";
import { Injectable } from "@angular/core";
import { Clients } from "./models/clients.model";
import { ClientApi } from "./client.api";
import { SearchClientDto } from "./dto/search-client.dto";

@Injectable()
export class ClientAddressTypeaheadProvider extends NiceAsyncTypeaheadProvider<Clients> {
    public resource = "clients_addresses";

    private pageSize = 20;

    constructor(private clientApi: ClientApi) {
        super();
    }

    public getById(id: number): Promise<Clients> {
        return lastValueFrom(this.clientApi.getById(id.toString()));
    }

    public async search(searchQuery: string, page: number, options?: SearchClientDto): Promise<NiceAsyncTypeaheadSearchResult<Clients>> {
        const dto: SearchClientDto = {
            ...options,
            query: searchQuery,
            page,
            length: this.pageSize,
            shipTo: true
        };

        const result: Clients[] = await lastValueFrom(this.clientApi.search(dto));

        return {
            items: result.map((x) => ({ ...x, id: x.dexRowId })),
            nextPage: result.length === this.pageSize ? page + 1 : null
        };
    }

    public format(client: Clients): string {
        return [client.address1.trim(), client.address2.trim(), client.city.trim(), client.state.trim()].join(" ") ?? "-";
    }
}
