import { Observable } from "rxjs";
import { share } from "rxjs/operators";
import { Manager, Socket } from "socket.io-client";
import { NiceToastService } from "@recursyve/nice-ui-kit.v2";

// https://socket.io/docs/v3/client-api/#Event-%E2%80%98disconnect%E2%80%99
enum SocketIoDisconnectReasons {
    ServerDisconnect = "io server disconnect",
    ClientDisconnect = "io client disconnect",
    PingTimeout = "ping timeout",
    TransportClose = "transport close",
    TransportError = "transport error"
}

export abstract class AclGateway {
    private socket: Socket;
    private observables: Record<string, Observable<any>> = {};
    private counter: Record<string, number> = {};

    private willConnect = false;

    protected constructor(namespace: string, manager: Manager, private niceToastService: NiceToastService) {
        this.socket = manager.socket(`/${namespace}`);
        this.socket.on("connect", this.onConnect.bind(this));
        this.socket.on("disconnect", this.onDisconnect.bind(this));
    }

    public connect(): void {
        this.willConnect = true;
        this.socket.connect();
    }

    public disconnect(): void {
        this.socket.emit("unsubscribe");
        this.socket.disconnect();
    }

    private onConnect(): void {
        this.socket.emit("subscribe");

        if (!this.willConnect) {
            this.niceToastService.success("websocket.reconnected");
        }
        this.willConnect = false;
    }

    private onDisconnect(reason: string): void {
        if (
            [
                SocketIoDisconnectReasons.ServerDisconnect,
                SocketIoDisconnectReasons.PingTimeout,
                SocketIoDisconnectReasons.TransportClose,
                SocketIoDisconnectReasons.TransportError
            ].includes(reason as SocketIoDisconnectReasons)
        ) {
            this.niceToastService.error("websocket.disconnected");
        }
    }

    protected listenForEvent<T>(event: string): Observable<T> {
        if (!this.counter[event]) {
            this.counter[event] = 0;
        }
        this.counter[event]++;

        if (!this.observables[event]) {
            this.observables[event] = new Observable((observer: any) => {
                const listener = (data: T) => {
                    observer.next(data);
                };
                this.socket.on(event, listener);
                return () => {
                    this.counter[event]--;
                    if (this.counter[event] === 0) {
                        delete this.observables[event];
                    }
                };
            }).pipe(share());
        }
        return this.observables[event];
    }
}
