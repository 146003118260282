import { Component, ViewEncapsulation } from "@angular/core";
import { NiceNavigationService } from "@recursyve/nice-ui-kit.v2";
import { Roles } from "../../../../api/acl/account/models/roles.model";
import { AppService } from "../../../../store/app.service";
import { AppQuery } from "../../../../store/app.query";

@Component({
    selector: "acl-toolbar",
    templateUrl: "toolbar.template.html",
    styleUrls: ["./toolbar.style.scss"],
    encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent {
    public currentUser$ = this.appQuery.selectCurrentUser();
    public currentRole$ = this.appQuery.selectCurrentRole();

    constructor(private navigationService: NiceNavigationService, private appService: AppService, private appQuery: AppQuery) {}

    public toggleSidebarOpen(key: string) {
        this.navigationService.getComponent(key).toggle();
    }

    public async logout() {
        await this.appService.logout();
    }

    public activateRole(role: Roles): void {
        this.appService.setCurrentRole(role.name);
    }
}
