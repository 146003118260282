import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AclApi } from "../acl.api";
import { BinGateway } from "./gateways/bin.gateway";
import { BinsData } from "./models/bins.model";

@Injectable()
export class BinApi extends AclApi {
    public get gateway(): BinGateway {
        return this.binGateway;
    }

    constructor(http: HttpClient, private binGateway: BinGateway) {
        super("bin", http);
    }

    public getAll(): Observable<BinsData[]> {
        return this.get();
    }

    public getAllAvailable(): Observable<BinsData[]> {
        return this.get("available");
    }
}
