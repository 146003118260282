import { Injectable } from "@angular/core";
import { RolesEnum } from "../api/acl/account/models/enums/roles.enum";
import { NavigationService } from "../navigation/navigation.service";
import { AppStore } from "./app.store";
import { ApiService } from "../api/api.service";
import { catchError, tap } from "rxjs/operators";
import { of } from "rxjs";
import { AppQuery } from "./app.query";
import { Router } from "@angular/router";

@Injectable({
    providedIn: "root"
})
export class AppService {
    constructor(
        private appStore: AppStore,
        private appQuery: AppQuery,
        private api: ApiService,
        private router: Router,
        private navigationService: NavigationService
    ) {}

    public init(): void {
        this.loadCurrentRole();
    }

    public loadServerState() {
        this.appStore.setLoading(true);
        this.api.info.getInfo()
            .pipe(
                tap((serverState) => {
                    this.appStore.update({ serverState });
                    this.appStore.setLoading(false);
                }),
                catchError((e) => {
                    this.appStore.setError(e);
                    this.appStore.setLoading(false);
                    return of(e);
                })
            ).subscribe();
        this.appStore.setLoading(false);
    }

    public async loadCurrentUser(): Promise<void> {
        this.appStore.setLoading(true);
        try {
            const account = await this.api.account.getInfo().toPromise();
            this.appStore.update({ currentUser: account });

            const currentRole = localStorage.getItem("role");
            if (!currentRole) {
                this.setCurrentRole(account.roles[0].name, false);
            } else {
                this.setNavigation();
            }
        } catch (e) {
            this.appStore.setError(e);
        } finally {
            this.appStore.setLoading(false);
        }
    }

    public async logout(): Promise<void> {
        await this.api.auth.logout().toPromise();
        this.appStore.update({ currentUser: undefined });
        this.router.navigate(["/login"]);
        localStorage.removeItem("role");
    }

    public setCurrentRole(role: RolesEnum, redirect = true): void {
        localStorage.setItem("role", role);

        const previousRole = this.appQuery.getValue().currentRole;

        this.appStore.update({ currentRole: role });
        this.setNavigation();

        // Don't redirect if you switch between account with basically the same permissions.
        if ([RolesEnum.Admin, RolesEnum.Counter].includes(previousRole) && [RolesEnum.Admin, RolesEnum.Counter].includes(role)) {
            return;
        }

        if (redirect) {
            this.router.navigate(["/dashboard"]);
        }
    }

    public loadCurrentRole(): void {
        const role = localStorage.getItem("role");
        this.appStore.update({
            currentRole: role as RolesEnum
        });
    }

    public setNavigation(): void {
        const { currentRole } = this.appQuery.getValue();
        this.navigationService.setNavigation(currentRole);
    }
}
