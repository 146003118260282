import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AclFilterApi } from "../filter/acl-filter.api";
import { TicketGateway } from "./gateways/ticket.gateway";
import { Tickets } from "./models/tickets.model";
import { CompleteBatchDto, TicketDto, TransferBatchDto, UndertakeTicketDto, UpdateTicketDto, UpdateTicketRankDto } from "./dto/ticket.dto";
import { SearchClientDto } from "../client/dto/search-client.dto";
import { TicketEquipmentAssociationDto } from "./dto/ticket-equipment-association.dto";
import { SearchTicketsDto } from "./dto/search-tickets.dto";

@Injectable()
export class TicketApi extends AclFilterApi<Tickets> {
    public get gateway(): TicketGateway {
        return this.ticketGateway;
    }

    constructor(http: HttpClient, private ticketGateway: TicketGateway) {
        super("ticket", http);
    }


    public createTicket(dto: TicketDto): Observable<Tickets> {
        return this.post("", dto);
    }

    public search(dto: SearchTicketsDto): Observable<Tickets[]> {
        return this.post("search", dto);
    }

    public getById(id: number): Observable<Tickets> {
        return this.get(`${id}`);
    }

    public getAllForToday(): Observable<Tickets[]> {
        return this.get("today");
    }

    public updateTicket(id: number, dto: UpdateTicketDto): Observable<Tickets> {
        return this.put<Tickets>(`${id}`, dto);
    }

    public undertakeTicket(id: number, dto: UndertakeTicketDto): Observable<void> {
        return this.put(`${id}/undertake`, dto);
    }

    public completeBatch(id: number, batchId: number, dto: CompleteBatchDto): Observable<boolean> {
        return this.put<{ completed: boolean }>(`${id}/batch/${batchId}/complete`, dto).pipe(map((x) => x.completed));
    }

    public transferBatch(id: number, batchId: number, dto: TransferBatchDto): Observable<void> {
        return this.put(`${id}/batch/${batchId}/transfer`, dto);
    }

    public emptyBatch(id: number, batchId: number): Observable<boolean> {
        return this.put<{ completed: boolean }>(`${id}/batch/${batchId}/empty`).pipe(map((x) => x.completed));
    }

    public moveTicketToToProduce(id: number, dto: UpdateTicketRankDto): Observable<void> {
        return this.put<void>(`${id}/to-produce`, dto);
    }

    public moveTicketToWaiting(id: number, dto: UpdateTicketRankDto): Observable<void> {
        return this.put<void>(`${id}/waiting`, dto);
    }

    public moveTicketToCompleted(id: number): Observable<Tickets> {
        return this.put<Tickets>(`${id}/completed`);
    }

    public updateTicketRank(id: number, dto: UpdateTicketRankDto): Observable<void> {
        return this.put<void>(`${id}/rank`, dto);
    }

    public updateTicketEquipmentAssociation(id: number, dto: TicketEquipmentAssociationDto): Observable<Tickets> {
        return this.put<Tickets>(`${id}/association`, dto);
    }

    public deleteTicket(id: number): Observable<void> {
        return this.delete<void>(`${id}`);
    }
}
