<mat-toolbar class="p-0 w-full" *ngIf="currentUser$ | async as currentUser">
    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
            <button mat-icon-button class="navbar-toggle-button flex lg:hidden" (click)="toggleSidebarOpen('navbar')">
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>
        </div>

        <div class="mr-4 menu-button" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
            <button mat-button disableRipple [matMenuTriggerFor]="userMenu" class="user-button border-transparent">
                <span class="flex items-center justify-center">
                    <ngx-avatars [name]="currentUser.fullName" size="42" class="mr-3"></ngx-avatars>
                    <span class="username mr-3 text-sm" fxHide fxShow.gt-sm>
                        {{ currentUser.fullName }}
                    </span>
                    <mat-icon class="text-sm" fxHide.xs>keyboard_arrow_down</mat-icon>
                </span>
            </button>

            <mat-menu #userMenu="matMenu" [overlapTrigger]="false" class="switch-nested-menu-arrows">
                <button mat-menu-item [matMenuTriggerFor]="subMenu" *ngIf="currentUser.roles.length > 1">
                    <mat-icon>admin_panel_settings</mat-icon>
                    <span>{{ "features.dashboard.components.toolbar.change_account" | translate }}</span>
                </button>
                <button class="text-primary" mat-menu-item (click)="logout()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Logout</span>
                </button>
            </mat-menu>

            <mat-menu #subMenu="matMenu">
                <ng-container *ngIf="currentRole$ | async as currentRole">
                    <ng-container *ngFor="let role of currentUser.roles">
                        <button mat-menu-item (click)="activateRole(role)" *ngIf="currentRole !== role.name">
                            <mat-icon>account_circle</mat-icon>
                            <span>{{ ("models.roles." + role.name) | translate }}</span>
                        </button>
                    </ng-container>
                </ng-container>
            </mat-menu>
        </div>
    </div>
</mat-toolbar>
