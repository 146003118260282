import { mixinNiceApi, NiceHttpExceptionFactory } from "@recursyve/nice-ui-kit.v2";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { mixinNiceFilterApi } from "@recursyve/nice-data-filter-kit";
import { Type } from "@angular/core";

export const _BaseApi = mixinNiceApi(
    class {
        public readonly apiUrl = environment.apiUrl;
        public readonly path: string;

        protected readonly _exceptionFactory: NiceHttpExceptionFactory;

        constructor(path: string, public httpClient: HttpClient) {
            this.path = path;
        }

        public get exceptionFactory(): NiceHttpExceptionFactory {
            return this._exceptionFactory;
        }
    }
);

export const _BaseFilterApi = (type?: Type<any>) =>
    mixinNiceFilterApi(type)(
        class extends _BaseApi {
            constructor(path: string, httpClient: HttpClient) {
                super(path, httpClient);
            }
        }
    );
