import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { _BaseApi } from "../../base.api";
import { Constants } from "./models/constants.model";

@Injectable()
export class ConstantsApi extends _BaseApi {
    constructor(protected http: HttpClient) {
        super("constant", http);
    }

    public getSpreadersChart(): Observable<Constants> {
        return this.get("spreaders-chart");
    }
}
