import { Injectable } from "@angular/core";
import { NiceNavigationItem, NiceNavigationService } from "@recursyve/nice-ui-kit.v2";
import { RolesEnum } from "../api/acl/account/models/enums/roles.enum";
import { adminNavigation } from "./admin.navigation";
import { userNavigation } from "./user.navigation";

@Injectable({
    providedIn: "root"
})
export class NavigationService {
    constructor(private niceNavigationService: NiceNavigationService) {
    }

    public async init() {
        this.niceNavigationService.storeNavigation("admin", adminNavigation);
        this.niceNavigationService.storeNavigation("user", userNavigation);
    }

    public setNavigation(role: RolesEnum): void {
        if ([RolesEnum.Admin, RolesEnum.Counter].includes(role)) {
            this.niceNavigationService.setCurrentNavigation("admin");
        } else {
            this.niceNavigationService.setCurrentNavigation("user");
        }
    }
}
