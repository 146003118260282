import { NiceNavigationItem } from "@recursyve/nice-ui-kit.v2";

export const userNavigation: NiceNavigationItem[] = [
    {
        id: "home",
        type: "group",
        children: [
            {
                id: "bin",
                title: "navigation.dumpsters",
                type: "basic",
                iconType: "fontawesome",
                icon: "fad fa-dumpster",
                link: "/dashboard/bin"
            },
            {
                id: "dashboard",
                title: "navigation.tickets",
                type: "basic",
                iconType: "fontawesome",
                icon: "fad fa-stream",
                link: "/dashboard/ticket"
            }
        ]
    }
];
