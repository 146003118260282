import { Injectable } from "@angular/core";
import { NiceAsyncTypeaheadProvider, NiceAsyncTypeaheadSearchResult } from "@recursyve/nice-ui-kit.v2";
import { lastValueFrom } from "rxjs";
import { Equipments } from "../models/equipments.model";
import { EquipmentsApi } from "./equipments.api";
import { SearchEquipmentsDto } from "../dto/search-equipments.dto";

@Injectable()
export class EquipmentTypeaheadProvider extends NiceAsyncTypeaheadProvider<Equipments> {
    public resource = "equipments";

    private pageSize = 10;

    constructor(private api: EquipmentsApi) {
        super();
    }

    public async search(searchQuery: string, page: number, options: SearchEquipmentsDto): Promise<NiceAsyncTypeaheadSearchResult<Equipments>> {
        const dto: SearchEquipmentsDto = {
            ...options,
            query: searchQuery,
            length: this.pageSize,
            page
        };

        const equipments = await lastValueFrom((this.api.search(dto)));
        return { items: equipments, nextPage: equipments.length === this.pageSize ? page + 1 : page };
    }

    public getById(id: number): Promise<Equipments> {
        return lastValueFrom(this.api.getById(id));
    }

    public format(equipment: Equipments): string {
        return equipment.identifier.toString();
    }
}
