import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { _BaseApi } from "../../../base.api";
import { GrainBoxCapacities } from "../models/grain-box-capacities.model";

@Injectable()
export class GrainBoxCapacitiesApi extends _BaseApi {
    constructor(private http: HttpClient) {
        super("grain-box-capacity", http);
    }

    public getAll(): Observable<GrainBoxCapacities[]> {
        return this.get("", { type: GrainBoxCapacities });
    }
}
