import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Manager } from "socket.io-client";
import { AclGateway } from "../../acl.gateway";
import { BinsData } from "../models/bins.model";
import { BinEventTypes } from "./event-types/bin.event-type";
import { NiceToastService } from "@recursyve/nice-ui-kit.v2";

@Injectable()
export class BinGateway extends AclGateway {
    constructor(@Inject(Manager) manager: Manager, niceToastService: NiceToastService) {
        super("bin", manager, niceToastService);
    }

    public get binUpdated$(): Observable<BinsData[]> {
        return this.listenForEvent(BinEventTypes.BinsUpdated);
    }
}
