import { Inject, Injectable } from "@angular/core";
import { NiceToastService } from "@recursyve/nice-ui-kit.v2";
import { Observable } from "rxjs";
import { Manager } from "socket.io-client";
import { AclGateway } from "../../acl.gateway";
import { MixersData } from "../models/mixers.model";
import { MixerEventTypes } from "./event-types/mixer.event-type";

@Injectable()
export class MixerGateway extends AclGateway {
    constructor(@Inject(Manager) manager: Manager, niceToastService: NiceToastService) {
        super("mixer", manager, niceToastService);
    }

    public get mixerUpdated$(): Observable<MixersData[]> {
        return this.listenForEvent(MixerEventTypes.MixersUpdated);
    }
}
