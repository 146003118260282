import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { UpsertEquipmentDto } from "../dto/upsert-equipment.dto";
import { _BaseFilterApi } from "../../../base.api";
import { Equipments } from "../models/equipments.model";
import { EquipmentsGateway } from "../gateway/equipments.gateway";
import { EquipmentsGetAllDto } from "../dto/equipments-get-all.dto";
import { SearchEquipmentsDto } from "../dto/search-equipments.dto";

@Injectable()
export class EquipmentsApi extends _BaseFilterApi(Equipments) {
    constructor(private http: HttpClient, private equipmentsGateway: EquipmentsGateway) {
        super("equipment", http);
    }

    public createEquipment(updateEquipmentDto: UpsertEquipmentDto): Observable<Equipments> {
        return this.post("", updateEquipmentDto, { type: Equipments });
    }

    public get gateway(): EquipmentsGateway {
        return this.equipmentsGateway;
    }

    public getById(id: number): Observable<Equipments> {
        return this.get(`${id}`, { type: Equipments });
    }

    public findAllByIdentifier(identifier: number): Observable<Equipments[]> {
        return this.get(`identifier/${identifier}`, { type: Equipments });
    }

    public search(dto?: SearchEquipmentsDto): Observable<Equipments[]> {
        return this.post("search", dto);
    }

    public getAll(dto?: EquipmentsGetAllDto): Observable<Equipments[]> {
        return this.get("", { params: { ...dto }, type: Equipments });
    }

    public updateEquipment(id: number, dto: UpsertEquipmentDto): Observable<Equipments> {
        return this.put(`${id}`, dto, { type: Equipments })
    }

    public updateArchive(id: number): Observable<Equipments> {
        return this.put(`${id}/archive`);
    }
}
