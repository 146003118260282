import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { _BaseApi } from "../../../base.api";
import { SpreaderWidths } from "../models/spreader-widths.model";

@Injectable()
export class SpreaderWidthsApi extends _BaseApi {
    constructor(private http: HttpClient) {
        super("spreader-width", http);
    }

    public getAll(): Observable<SpreaderWidths[]> {
        return this.get("", { type: SpreaderWidths });
    }
}
