import { Injectable } from "@angular/core";
import { AclApi } from "../acl.api";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Formulas } from "./models/formulas.model";
import { SearchFormulaDto } from "./dto/search-formula.dto";

@Injectable()
export class FormulaApi extends AclApi {
    constructor(http: HttpClient) {
        super("formula", http);
    }

    public getById(id: string): Observable<Formulas> {
        return this.get<Formulas>(encodeURIComponent(id));
    }

    public search(dto: SearchFormulaDto): Observable<Formulas[]> {
        return this.post<Formulas[]>("search", dto);
    }
}
