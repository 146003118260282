import { Injectable } from "@angular/core";
import { BinApi } from "./acl/bin/bin.api";
import { MixerApi } from "./acl/mixers/mixer.api";
import { TicketApi } from "./acl/ticket/ticket.api";
import { InfoApi } from "./info/info.api";
import { AuthApi } from "./acl/auth/apis/auth.api";
import { AccountApi } from "./acl/account/account.api";
import { ClientApi } from "./acl/client/client.api";
import { FormulaApi } from "./acl/formula/formula.api";
import { EquipmentsApi } from "./acl/equipments/apis/equipments.api";
import { EquipmentStatusHistoriesApi } from "./acl/equipments-status-histories/apis/equipment-status-histories.api";
import { AssetsApi } from "./acl/assets/apis/assets.api";
import { ConstantsApi } from "./acl/constants/constants.api";
import { SpreaderWheelsApi } from "./acl/equipments/apis/spreader-wheels.api";
import { SpreaderHeightsApi } from "./acl/equipments/apis/spreader-heights.api";
import { SpreaderWidthsApi } from "./acl/equipments/apis/spreader-widths.api";
import { GrainBoxSizesApi } from "./acl/equipments/apis/grain-box-sizes.api";
import { GrainBoxRollingTypesApi } from "./acl/equipments/apis/grain-box-rolling-types.api";
import { GrainBoxWheelsApi } from "./acl/equipments/apis/grain-box-wheels.api";
import { GrainBoxCapacitiesApi } from "./acl/equipments/apis/grain-box-capacities.api";
import { NurseWheelsApi } from "./acl/equipments/apis/nurse-wheels.api";
import { OtherEquipmentNamesApi } from "./acl/equipments/apis/other-equipment-names.api";

@Injectable({
    providedIn: "root"
})
export class ApiService {
    constructor(
        private accountApi: AccountApi,
        private authApi: AuthApi,
        private binApi: BinApi,
        private clientApi: ClientApi,
        private formulaApi: FormulaApi,
        private infoApi: InfoApi,
        private mixerApi: MixerApi,
        private ticketApi: TicketApi,
        private equipmentApi: EquipmentsApi,
        private equipmentStatusApi: EquipmentStatusHistoriesApi,
        private spreaderWheelsApi: SpreaderWheelsApi,
        private spreaderHeightsApi: SpreaderHeightsApi,
        private spreaderWidthsApi: SpreaderWidthsApi,
        private grainBoxSizesApi: GrainBoxSizesApi,
        private grainBoxRollingTypesApi: GrainBoxRollingTypesApi,
        private grainBoxWheelsApi: GrainBoxWheelsApi,
        private grainBoxCapacitiesApi: GrainBoxCapacitiesApi,
        private nurseWheelsApi: NurseWheelsApi,
        private otherEquipmentNamesApi: OtherEquipmentNamesApi,
        private assetApi: AssetsApi,
        private constantApi: ConstantsApi
    ) {
    }

    public get account(): AccountApi {
        return this.accountApi;
    }

    public get auth(): AuthApi {
        return this.authApi;
    }

    public get bin(): BinApi {
        return this.binApi;
    }

    public get client(): ClientApi {
        return this.clientApi;
    }

    public get formula(): FormulaApi {
        return this.formulaApi;
    }

    public get info(): InfoApi {
        return this.infoApi;
    }

    public get mixer(): MixerApi {
        return this.mixerApi;
    }

    public get ticket(): TicketApi {
        return this.ticketApi;
    }

    public get equipment(): EquipmentsApi {
        return this.equipmentApi;
    }

    public get equipmentStatusHistory(): EquipmentStatusHistoriesApi {
        return this.equipmentStatusApi;
    }

    public get spreaderWheels(): SpreaderWheelsApi {
        return this.spreaderWheelsApi;
    }

    public get spreaderHeights(): SpreaderHeightsApi {
        return this.spreaderHeightsApi;
    }

    public get spreaderWidths(): SpreaderWidthsApi {
        return this.spreaderWidthsApi;
    }

    public get grainBoxSizes(): GrainBoxSizesApi {
        return this.grainBoxSizesApi;
    }

    public get grainBoxRollingTypes(): GrainBoxRollingTypesApi {
        return this.grainBoxRollingTypesApi;
    }

    public get grainBoxWheels(): GrainBoxWheelsApi {
        return this.grainBoxWheelsApi;
    }

    public get grainBoxCapacities(): GrainBoxCapacitiesApi {
        return this.grainBoxCapacitiesApi;
    }

    public get nurseWheels(): NurseWheelsApi {
        return this.nurseWheelsApi;
    }

    public get otherEquipmentNames(): OtherEquipmentNamesApi {
        return this.otherEquipmentNamesApi;
    }

    public get asset(): AssetsApi {
        return this.assetApi;
    }

    public get constant(): ConstantsApi {
        return this.constantApi;
    }
}
