import { Routes } from "@angular/router";

export const DASHBOARD_ROUTES: Routes = [
    { path: "", redirectTo: "ticket", pathMatch: "full" },
    {
        path: "bin",
        loadChildren: () => import("./bins/bins.module").then(m => m.BinsModule)
    },
    {
        path: "ticket",
        loadChildren: () => import("./tickets/tickets.module").then(m => m.TicketsModule)
    },
    {
        path: "spreader",
        loadChildren: () => import("./equipments/equipments.module").then(m => m.EquipmentsModule)
    },
    {
        path: "grain-box",
        loadChildren: () => import("./equipments/equipments.module").then(m => m.EquipmentsModule)
    },
    {
        path: "nurse",
        loadChildren: () => import("./equipments/equipments.module").then(m => m.EquipmentsModule)
    },
    {
        path: "vapam",
        loadChildren: () => import("./equipments/equipments.module").then(m => m.EquipmentsModule)
    }
];
