<nice-layout>
    <!-- Navigation -->
    <acl-navbar></acl-navbar>

    <!-- Wrapper -->
    <div class="flex flex-col flex-auto w-full min-w-0 h-screen">
        <!-- Header -->
        <div class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 print:hidden">
            <!-- Components -->
            <div class="flex justify-end items-center pl-2 ml-auto space-x-2 w-full">
                <acl-toolbar class="w-full"></acl-toolbar>
            </div>
        </div>

        <!-- Content -->
        <div class="page-content flex flex-col flex-auto inset-0 overflow-y-auto h-full">
            <router-outlet></router-outlet>
        </div>
    </div>
</nice-layout>
